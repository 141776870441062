import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo' 

const ContactusPage = ({ data }) => (  
  <Layout> 
    <Seo
      title={"findbestwebhosting.com ::Contact Us"}
      description={
        'findbestwebhosting.com ::Contact Us'
      }
    />
<React.Fragment>
<h2 class="section"><b>
findbestwebhosting.com ::Contact Us
   </b></h2>
 
 
<iframe src="https://jotform.com/form/90505741259" frameBorder="0" title="contact us "
              style={{width: "100%", height:"640px", border: "none",}} scrolling="no">


</iframe>

 
    </React.Fragment>
  </Layout>
)

export default ContactusPage
